import React from "react"
import { Link, graphql } from "gatsby"
import styles from "@one-builder/gatsby-theme-ob-master/src/styles/global.module.scss"
import { Parallax } from "react-parallax"

// Components
import Layout from "@one-builder/gatsby-theme-ob-master/src/components/layout/layout"
import MarkdownContent from "@one-builder/gatsby-theme-ob-master/src/components/content"
import FeaturedServices from "../components/home/featured-services"
import LatestWork from "../components/home/latest-work"
// import NewsletterSignup from "../components/home/email-signup"
import NewsletterSignup from "@one-builder/gatsby-theme-ob-master/src/components/home/newsletter-signup"
import { useGetTokens } from "@one-builder/gatsby-theme-ob-master/src/hooks/useGetTokens"
import convertTokens from "@one-builder/gatsby-theme-ob-master/src/helpers/convertTokens"
import Image from "@one-builder/gatsby-theme-ob-master/src/components/common/image"
import BackgroundImage from "@one-builder/gatsby-theme-ob-master/src/components/common/background-image"
import setSeoData from "@one-builder/gatsby-theme-ob-master/src/helpers/setSeoData"

export default ({ data }) => {
  // Get metadata
  const metadata = useGetTokens()

  const home = data.markdownRemark

  let seo_data = setSeoData(home.frontmatter)

  return (
    <Layout seo_data={seo_data}>
      <section id={styles.bannerContent1}>
        <div className={`${styles.container} ${styles.flexWrapper}`}>
          <div className={styles.banner1Content}>
            <p>Reliable, Trusted, and Professional Home and Commercial Repair Services</p>
            <h2>Let us get to work for you!</h2>
            <div className={`${styles.buttonContain}`}>
              <Link className={styles.btn} to={`/contact/`} title="Contact Us">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.banner1ImgContain}>
          <Image filename="plug.png" alt="Electical" />
        </div>
      </section>

      <Parallax bgImage={"/images/bg.jpg"} strength={-200} id={styles.bannerBg}>
        <section id={styles.bannerContent2}>
          <div className={styles.container}>
            <h2>Don’t spend another day in the dark!</h2>
            <div className={`${styles.buttonContain}`}>
              <Link
                className={styles.btn}
                to={`/request-a-quote/`}
                title="Request A Quote"
              >
                Request A Quote
              </Link>
            </div>
          </div>
        </section>
      </Parallax>
      {/* <FeaturedServices /> */}

      <section id={styles.ctaSection}>
        <div className={styles.container}>
          <div id={styles.ctaContain} className={styles.flexWrapper}>
            <BackgroundImage
              filename="cta1.jpg"
              alt="Electrical Work"
              className={styles.ctaItem}
            >
              <Link
                className={styles.btn}
                to={`/services/electrical/`}
                title="Electrical Work"
              >
                <p>
                  Electrical Work <span>&raquo;</span>
                </p>
              </Link>
            </BackgroundImage>
            <BackgroundImage filename="cta2.jpg" alt="Handyman Services">
              <Link
                className={styles.btn}
                to={`/services/handyman/`}
                title="Handyman Services"
              >
                <p>
                  Handyman <span>&raquo;</span>
                </p>
              </Link>
            </BackgroundImage>
            <BackgroundImage filename="cta3.jpg" alt="Residential">
              <Link
                className={styles.btn}
                to={`services/plumbing-heating-and-air-conditioning/`}
                title="Plumbing"
              >
                <p>
                  Plumbing <span>&raquo;</span>
                </p>
              </Link>
            </BackgroundImage>
          </div>
          <p id={styles.ctaInfo}>
            Fully insured and licensed in PA <span>PAHIC #150586</span>
          </p>
        </div>
      </section>

      <section id={styles.about_content}>
        <div className={styles.container}>
          <div className={styles.about_sub}>
            <MarkdownContent
              content={convertTokens(home.frontmatter.aboutSection, metadata)}
            />
            <div className={`${styles.buttonContain}`}>
              <Link className={styles.btn} to={`/about/`} title="Read More">
                Read More
              </Link>
            </div>
          </div>
          <div className={styles.about_sub}>
            <div className={styles.cord}>
              <Image filename="cord.png" alt="Electrical Chord" />
            </div>
          </div>
        </div>
      </section>

      {/* <LatestWork /> */}

      <section id={styles.sub_ctaSection}>
        <div className={styles.container}>
          <h2>See what we can do for you!</h2>
          <p>Take a look at some of the services we provide.</p>
          <div id={styles.subCtaContain} className={styles.flexWrapper}>
            <BackgroundImage filename="sub-cta-2.jpg" alt="Alarm Installation">
              <Link
                className={styles.btn}
                to={`/services/electrical/burglar-alarm-installation/`}
                title="Alarm Installation"
              >
                <p>
                  Alarm<br />Installation <span>&raquo;</span>
                </p>
              </Link>
            </BackgroundImage>
            
            <BackgroundImage filename="sub-cta-4.jpg" alt="Sink Faucet">
              <Link
                className={styles.btn}
                to={`/services/plumbing-heating-and-air-conditioning/plumbing-repair/`}
                title="Plumbing Repair"
              >
                <p>
                  Plumbing<br />Repair <span>&raquo;</span>
                </p>
              </Link>
            </BackgroundImage>
            <BackgroundImage filename="security-camera.jpg" alt="Security Camera Installation">
              <Link
                className={styles.btn}
                to={`/services/electrical/surveillance-camera-installation/`}
                title="Surveillance Camera Installation"
              >
                <p>
                Surveillance<br />Camera Installation <span>&raquo;</span>
                </p>
              </Link>
            </BackgroundImage>
          </div>
        </div>
      </section>

      <Parallax
        bgImage={"/images/welcomebg.jpg"}
        strength={-200}
        id={styles.bannerBg}
      >
        <section id={styles.welcome}>
          <div className={styles.container}>
            <div className={styles.welcome_sub}>
              <MarkdownContent
                content={convertTokens(
                  home.frontmatter.welcomeSection,
                  metadata
                )}
              />
            </div>
            <div className={styles.welcome_sub}>
              <h3>
                Let us see what we can do for you on your next home project!
              </h3>
              <div className={`${styles.buttonContain}`}>
                <Link
                  className={styles.btn}
                  to={`/request-a-quote/`}
                  title="Get a Quote"
                >
                  Get a Quote
                </Link>
              </div>
            </div>
          </div>
        </section>
      </Parallax>

      <section id={styles.newsletter}>
        <div className={styles.container}>
          <div>
            <h2>Stay in touch</h2>
            <p>
              Subscribe to receive our latest news and offers from Jures
              Electrical LLC.
            </p>
          </div>
          <div>
            <NewsletterSignup />
          </div>
        </div>
      </section>

      {/* <section id={styles.assocLogos}>
        <div className={styles.container}>
          <div className={styles.assocSub}>
            <Image
              className={styles.brush}
              filename="painter.png"
              alt="Paint Brush"
            />
          </div>
          <div className={styles.assocSub}>
            <div style={{ width: "100%" }}>
              <Image filename="houzz-coupons.png" alt="Houzz" />
            </div>
            <div style={{ width: "100%" }}>
              <Image filename="small_logo.png" alt="Angies List" />
            </div>
          </div>
        </div>
      </section> */}

      {/* <section id={styles.testimonials}>
        <div className={styles.container}>
          <TestimonialSlider />
        </div>
      </section> */}

      {/* <section id={styles.hpRemodeling}>
        <div className={styles.container}>
          <div className={styles.flexWrapper}>
            <div id={styles.hpRemodelingImg}>
              <img
                src="/images/windows-and-doors.jpg"
                alt="Remodeling Services"
              />
            </div>
            <div id={styles.hpRemodelingContent}>
              <MarkdownContent
                content={convertTokens(
                  home.frontmatter.remodelingSection,
                  metadata
                )}
              />
              <div className={styles.buttonContain}>
                <Link
                  className={styles.btn}
                  to={`/remodeling`}
                  title="Remodeling Services"
                >
                  View Remodeling Services
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </Layout>
  )
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { title: { eq: "Home" } }) {
      id
      frontmatter {
        title
        meta {
          title
          description
          page_visible
        }
        template
        welcomeSection
        aboutSection
        testimonials {
          name
          review
        }
      }
    }
  }
`
